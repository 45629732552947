import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { AppService } from '../../services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import { AuthService } from '@capp/app/auth/auth-service';
import { AppUpdateService } from '@capp/app/services/app-update-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: PhoenixWebService }]
})
export class AppComponent implements OnDestroy, AfterViewInit {
  @ViewChild('confirmationModal', { static: true }) modalTemplateRef: TemplateRef<any>;
  @ViewChild('informationModal', { static: true }) infoModalTemplateRef: TemplateRef<any>;
  @ViewChild('clientDelinquentModal', { static: true }) clientDelinquentModalTemplateRef: TemplateRef<any>;
  public confirmMessage: string;
  public infoMessage: string;
  public modalRef: BsModalRef;
  public alerts: Array<any> = [];
  public alertMessageSub: Subscription;
  public confirmModalSub: Subscription;
  public informationModalSub: Subscription;
  public clientDelinquentModalSub: Subscription;
  public features: any;

  constructor(
    public appService: AppService, private authService: AuthService, private phoenixWebService: PhoenixWebService, updateService: AppUpdateService) {
    this.authService.ensureSessionIsEstablished();
    this.confirmModalSub = this.appService.modalConfirmTitleSubject.subscribe((title: string) => {
      this.confirmMessage = title;
    });
    this.informationModalSub = this.appService.modalInformationTitleSubject.subscribe((title: string) => {
      this.infoMessage = title;
    });
    this.alertMessageSub = this.appService.alertMessageSubject.subscribe((res) => {
      this.alerts.push({
        title: res.title,
        type: res.type,
        timeout: 8000
      });
    });
  }

  ngAfterViewInit(): void {
    this.appService.modalRef = this.modalRef;
    this.appService.modalConfirmTemplate = this.modalTemplateRef;
    this.appService.modalInformationTemplate = this.infoModalTemplateRef;
    this.appService.modalClientDelinquentTemplate = this.clientDelinquentModalTemplateRef;
  }

  ngOnDestroy(): void {
    this.confirmModalSub.unsubscribe();
    this.alertMessageSub.unsubscribe();
  }
}
