export class Constants {
    public static readonly ALLOWED_FILE_TYPES = ["text/plain","image/jpeg", "image/png","image/gif","application/pdf"];
}

export enum AppIdentifier {
    CLIENT = 'client-webapp',
    VENDOR = 'vendor-webapp',
}


export enum ComplianceLevelID {
    NonCompliant = 1,
    PartiallyCompliant = 2,
    FullyCompliant = 3,
    MinorCompliant = 4
}


export enum UserPermissionsSecurable {
    ALL = "ALL",
    JOBS = "JOBS",
    LEASES = "LEASES",
    MESSAGES = "MESSAGES",
    LOCATIONS = "LOCATIONS",
    USERS = "USERS",
    VENDORS = "VENDORS",
    TENANTS = "TENANTS",
    CLIENT_SETTINGS = "CLIENT_SETTINGS",
    CLIENT_USER_SETTINGS = "CLIENT_USER_SETTINGS",
    BILLING = "BILLING"
}

export enum UserPermissionsOperation {
    ALL = "ALL",
    READ = "READ",
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE"
}

export enum SortingDirection {
    None = 'none',
    Asc = 'asc',
    Desc = 'desc'
}

export enum ColumnType {
    ViewButton,
    CheckBox,
    Money,
    CustomButton,
    Compliance,
    FeeDue,
    DeficiencyStatus,
    Icon,
    Rating,
    JobTypes,
    LeaseTypes,
    JobTrades,
    VendorLink,
    Ellipsis,
    Rows,
    RowsMoney,
    Date,
    /*#region NewColumnTypes*/ // For the new my tasks page.
    Status,
    TaskName,
    DueDate,
    /*#endregion NewColumnTypes*/
}

export enum DeficiencyStatusID {
    Major = 0,
    Compliant = 1,
    Minor = 2,
    FeeDue = 3
}
