import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'phx-ckeditor',
  styleUrls: ['./phoenix-ckeditor.component.scss'],
  template: `
    <div><ckeditor [(ngModel)]="editorContent" [config]="editorConfig" (ngModelChange)="onModelChange()"></ckeditor></div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoenixCkeditorComponent),
      multi: true
    }
  ]
})
export class PhoenixCkeditorComponent implements ControlValueAccessor, OnInit, OnDestroy {
  editorContent = '';
  editorConfig = {
    // Define changes to default configuration here.
    // For complete reference see:
    // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html
    // The toolbar groups arrangement, optimized for two toolbar rows.
    toolbarGroups: [
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing'],
      },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'colors', groups: ['colors'] },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
      },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
    ],
    // Remove some buttons provided by the standard plugins, which are
    // not needed in the Standard(s) toolbar.
    removeButtons:
      'Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Button,Select,Textarea,TextField,Radio,Checkbox,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,RemoveFormat,Blockquote,CreateDiv,BidiRtl,BidiLtr,Language,Link,Unlink,Anchor,Image,Flash,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About',

    allowedContent: true,
    versionCheck: false,
    extraAllowedContent: '*(*)',
    // Set the most common block elements.
    format_tags: 'p;h1;h2;h3;pre',
    // Set extra plugins
    language: 'en',
    toolbar: 'mini',
    removePlugins:
      'contextmenu,liststyle,tabletools,tableselection,magicline,elementspath,resize',
    extraPlugins: 'indentblock',
    disableNativeSpellChecker: false,
    height: 400,
    // Simplify the dialog windows.
    removeDialogTabs: 'image:advanced;link:advanced',
  };

  private onChange: any;
  private onTouched: any;

  ngOnInit() {
    this.onTouched = () => { };
    this.onChange = (value: any) => { };
  }

  ngOnDestroy() {
    this.onTouched();
  }

  writeValue(value: any) {
    this.editorContent = value || '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onModelChange() {
    this.onChange(this.editorContent);
  }
}
