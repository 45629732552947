<div *ngIf="isInitialized" class="d-flex" style="position: absolute; top:0; bottom: 0; right: 0; left: 0; min-width: 700px; overflow: hidden;">
    <sidebar></sidebar>

    <div class="w-100 d-flex flex-column" style="overflow: auto;">
        <top-bar></top-bar>
        <div class="h-100 container-fluid main-container">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
