<div class="search-container">
  <div class="input-group search-input" [ngClass]="hideSearch ? 'hide-search' : ''">
    <div [class.disabled]="disabled"
         class="search-box-container">
      <phx-icon customClass="standardImage" src="search"></phx-icon>
      <input (input)="onSearch()"
             [(ngModel)]="searchInputValue"
             [disabled]="disabled"
             class="form-control"
             data-testid="search-input"
             placeholder="Search"
             style="height:30px;"
             type="text">
    </div>
  </div>
  <div *ngIf="showFilterButton" [class.disabled]="disabled" class="filter-box">
    <phx-button
      (click)="showModal(template)"
      [color]="filterCount > 0 ? 'light-blue' : 'tertiary'"
      [disabled]="disabled"
      [text]="'Filter ' + (filterCount > 0 ? '(' + filterCount + ')' : '')"
      class="filter-btn"
      size="small-no-width">
    </phx-button>
    <span (click)="onClearFilter()" *ngIf="filterCount > 0" class="clear-button">
        Clear
      </span>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Filter Results</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body filter-container">
    <ng-container *ngIf="!isNetworkFilter">
      <div class="fw-700">
        {{ companyLabel }} Properties
      </div>
      <hr>
      <div class="form-group">
        <label class="fw-700">{{ companyLabel }} Compliance</label>
        <phx-multi-select [alterCustomObject]="true"
                          [disabled]="disableCompliance"
                          [dropdownId]="'sub-account-filter-multi-select'"
                          [options]="vendorComplianceLevels"
                          [selectedItems]="selectedVendorCompliance"
                          data-test-id="filter-property-select-sub-account-compliance"
                          emptyDropdownMessage="No Compliance Items Found"
                          idField="id"
                          valueField="label">
        </phx-multi-select>
      </div>
      <div *ngFor="let property of accountProperties" class="form-group">
        <label *ngIf="canShowProperty(property)" class="fw-700">{{ property.customFieldName }}</label>
        <phx-multi-select *ngIf="canShowProperty(property)"
                          (selectedOptions)="propertySelected($event,false, property)"
                          [alterCustomObject]="true"
                          [disabled]="disableCompliance"
                          [dropdownId]="getName(property) + '-filter-multi-select'"
                          [options]="property.options"
                          [selectedItems]="getSelectedAccountProperties(property)"
                          data-test-id="filter-property-select-{{property.customFieldName}}"
                          emptyDropdownMessage="No Properties Found"
                          idField="id"
                          valueField="name">
        </phx-multi-select>
      </div>
      <div class="fw-700 mt-5">{{ certificateLabel }} Properties</div>
      <hr>
      <div class="form-group">
        <label class="fw-700">{{ certificateLabel }} Compliance</label>
        <phx-multi-select [alterCustomObject]="true"
                          [disabled]="disableCompliance"
                          [dropdownId]="'sub-account-filter-multi-select'"
                          [options]="jobComplianceLevels"
                          [selectedItems]="selectedCompliance"
                          data-test-id="filter-property-select-sub-account-compliance"
                          emptyDropdownMessage="No Compliance Items Found"
                          idField="id"
                          valueField="label">
        </phx-multi-select>
      </div>
      <div *ngFor="let property of subaccountProperties" class="form-group">
        <label *ngIf="canShowProperty(property)" class="fw-700">{{ property.customFieldName }}</label>
        <phx-multi-select *ngIf="canShowProperty(property)"
                          (selectedOptions)="propertySelected($event, true, property)"
                          [alterCustomObject]="true"
                          [disabled]="disableCompliance"
                          [dropdownId]="getName(property) + '-filter-multi-select'"
                          [options]="property.options"
                          [selectedItems]="getSelectedSubAccountProperties(property)"
                          data-test-id="filter-property-select-{{property.customFieldName}}"
                          emptyDropdownMessage="No Properties Found"
                          idField="id"
                          valueField="name">
        </phx-multi-select>
      </div>
    </ng-container>
    <ng-container *ngIf="isNetworkFilter">
      <div class="fw-700">Search Criteria</div>
      <hr>
      <div class="form-group mb-3">
        <label class="fw-700">Trades</label>
        <ng-select [(ngModel)]="selectedTrades"
                   [closeOnSelect]="false"
                   [items]="trades"
                   [multiple]="true"
                   [searchWhileComposing]="true"
                   bindLabel="name"
                   bindValue="id"
                   class="form-control p-0"
                   placeholder="Select an option">
          <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
            <input [ngModelOptions]="{standalone: true}"
                   [ngModel]="item$.selected"
                   id="item-{{index}}"
                   type="checkbox" /> {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
      <div class="d-flex align-items-center justify-content-center" style="margin-top: 3rem">
        <span>Search within</span>
        <input [(ngModel)]="value" class="ml-2 mr-2 form-control" style="height:30px; width: 75px;" type="text">
        <span>miles of: </span>
        <location-typeahead (onLocationChange)="onLocationSelect($event)" [placeholder]="locationPlaceholder || ''"
                            class="ml-2"
                            id="address">
        </location-typeahead>
      </div>
      <div class="custom-slider">
        <ngx-slider [(value)]="value" [options]="options" id="radius"></ngx-slider>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <div class="row ">
      <div class="col">
        <phx-button (click)="onClearFilter()" color="secondary" data-id="clientPhoenixFilterSearchBtn"
                    text="Clear Filter">
        </phx-button>
      </div>
      <div class="col">
        <phx-button (click)="onSearch();" color="primary" data-id="clientPhoenixFilterSearchBtn" text="Filter Result">
        </phx-button>
      </div>
    </div>
  </div>
</ng-template>
