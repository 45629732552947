import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

//TODO: Law clean this component up
@Component({
  selector: 'phx-multi-select',
  templateUrl: './phoenix-multi-select.component.html',
  styleUrls: ['./phoenix-multi-select.component.scss']
})
export class PhoenixMultiSelectComponent implements OnInit, OnChanges {
  @Input() options: any[] = [];
  @Input() dropdownId = '';
  @Input() selectedItems: any[] = [];
  @Input() size = '';
  @Input() emptyDropdownMessage = '';
  @Input() alterCustomObject = false;
  @Input() idField = 'id';
  @Input() valueField = 'value';
  @Input() disabled = false;
  @Input() error = false;
  @Output() selectedOptions: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
  @ViewChild('dropdownContent', { static: false }) dropdownContent: ElementRef;
  @ViewChild('phxMultiSelect', { static: false }) phxMultiSelect: ElementRef;

  public input = '';
  public filteredOptions = [];
  public expandItems = false;

  ngOnInit(): void {
    this.filteredOptions = this.filterOptions();
  }

  ngOnChanges(): void {
    this.filteredOptions = this.filterOptions();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Element): void {
    if (!targetElement?.classList?.contains('phx-multi-select-selector') && !this.disabled) {
      this.hideDropdownContent();
    }
  }

  public showDropdownContent(evt: any) {
    evt.stopPropagation();
    if (!this.disabled) {
      const inputHasFocus = this.inputElement?.nativeElement === document.activeElement;
      if (!this.dropdownContent?.nativeElement?.classList.contains('display-block') || inputHasFocus) {
        this.hideAllDropdowns();
        this.dropdownContent?.nativeElement?.classList.add('display-block');
      } else {
        this.hideAllDropdowns();
        this.dropdownContent?.nativeElement?.classList.remove('display-block');
      }
    }
  }

  public hideDropdownContent() {
    setTimeout(() => {
      if (
        this.dropdownContent?.nativeElement?.classList.contains('display-block')
      ) {
        this.dropdownContent?.nativeElement?.classList.remove('display-block');
      }
    }, 100);
  }

  public hideAllDropdowns() {
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('dropdown-content');
    for (let i = 0; i <= elements.length - 1; i++) {
      elements[i].classList.remove('display-block');
    }
  }

  public onSearch() {
    this.filteredOptions = this.filterOptions();
  }

  public selectedItem(evt: any, item: any) {
    this.inputElement.nativeElement.focus();
    if (this.alterCustomObject && !this.disabled) {
      if (!this.selectedItems?.includes(item)) {
        this.selectedItems?.push(item);
      }
    } else {
      if (this.selectedItems?.filter((f) => f.id == item.id).length <= 0) {
        this.selectedItems?.push({
          id: this.selectedItems?.length + 1,
          value: item.value
        });
      } else {
        this.selectedItems?.push({
          id: this.selectedItems?.length + 1,
          value: item.value
        });
      }
    }
    this.selectedOptions.emit(this.selectedItems);
    this.filteredOptions = this.filterOptions();
    this.error = false;
  }

  public removedItem(evt: any, item: any) {
    evt.stopPropagation();
    this.inputElement.nativeElement.focus();
    if (this.alterCustomObject && !this.disabled) {
      if (this.selectedItems.filter((f) => f[this.idField] == item[this.idField]).length > 0) {
        const arr = this.selectedItems.filter(
          (f) => f[this.idField] !== item[this.idField]
        );
        this.selectedItems = arr.slice(0);
      }
    } else {
      if (this.selectedItems.filter((f) => f.id == item.id).length > 0) {
        const arr = this.selectedItems.filter(
          (f) => f.id !== item.id
        );
        this.selectedItems = arr.slice(0);
      }
    }
    this.filteredOptions = this.filterOptions();
    this.selectedOptions.emit(this.selectedItems);
  }

  public filterOptions() {
    if (this.alterCustomObject && !this.disabled) {
      return this.options?.filter((f) => this.filterDropdownOptions(f));
    } else {
      return this.options?.filter((f) =>
        f?.value?.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())
      );
    }
  }

  private filterDropdownOptions(option: any) {
    const containsInputText = this.input == '' || option[this.valueField]?.toLocaleLowerCase().includes(this.input.toLocaleLowerCase());
    const isNotSelected = !this.selectedItems?.includes(option);
    return isNotSelected && containsInputText;
  }

  public onMouseOver(event: any) {
    event.target.addEventListener('wheel', (evt) => {
      evt.preventDefault();
      event.target.scrollLeft += (evt.deltaY + 10);
    });
  }

  public onButtonPress(evt: any) {
    if (this.alterCustomObject && !this.disabled) {
      if (evt.code === 'Space' || evt.code === 'Enter' || evt.code === 'NumpadEnter' || evt.code === 'Semicolon' || evt.code === 'Comma') {
        if (evt.code !== 'Enter' && evt.code !== 'NumpadEnter') {
          this.input = this.input.slice(0, this.input.length - 1);
        }
        const option = this.options.find(o => o[this.valueField] == this.input.trim());
        if (option) {
          this.error = false;
          this.selectedItem(null, option);
          setTimeout(() => {
            this.input = '';
          }, 100);
        } else if (this.input.trim().toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          this.error = false;
          const item = { id: null, value: this.input.trim() };
          this.selectedItem(null, item);
          setTimeout(() => {
            this.input = '';
          }, 100);
        } else {
          this.error = true;
          this.eventEmitter.emit();
        }
      }
    } else {
      if (evt.code === 'Space' || evt.code === 'Enter' || evt.code === 'NumpadEnter' || evt.code === 'Semicolon' || evt.code === 'Comma') {
        if (evt.code !== 'Enter' && evt.code !== 'NumpadEnter') {
          this.input = this.input.slice(0, this.input.length - 1);
        }
        const option = this.options.find(o => o.value == this.input.trim());
        if (option) {
          this.error = false;
          this.selectedItem(null, option);
          setTimeout(() => {
            this.input = '';
          }, 100);
        } else if (this.input.trim().toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          this.error = false;
          const item = { id: null, value: this.input.trim() };
          this.selectedItem(null, item);
          setTimeout(() => {
            this.input = '';
          }, 100);
        } else {
          this.error = true;
          this.eventEmitter.emit();
        }
      }
    }
  }

  public onKeyPress(evt: any) {
    if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {
      const counter: number = evt.code === 'ArrowUp' ? -1 : 1;
      const lastTabIndex = this.filteredOptions.length;
      const tabbables: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(`${this.dropdownId}-option`) as HTMLCollectionOf<HTMLElement>;
      let currentElement: HTMLElement = null;
      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i] === document.activeElement) {
          currentElement = tabbables[i];
        }
      }

      let curIndex = currentElement?.tabIndex ?? 0;
      if (curIndex === lastTabIndex && counter > 0) {
        curIndex = 0;
      } else if (curIndex === 1 && counter < 0) {
        curIndex = lastTabIndex + 1;
      }

      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i].tabIndex === (curIndex + counter)) {
          tabbables[i].focus(); //if it's the one we want, focus it and exit the loop
          break;
        }
      }
    }
  }

  public selectItemWithKeyPress(evt: any, item: any) {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      this.selectedItem(evt, item);
    }
  }

  generateItemPlaceholder() {
    if (this.alterCustomObject) {
      const items: string[] = this.selectedItems.slice(2, this.selectedItems.length).map(i => i[this.valueField]);
      return items.join(', ');
    } else {
      const items: string[] = this.selectedItems.slice(2, this.selectedItems.length).map(i => i.value);
      return items.join(', ');
    }
  }

  expandAllItems() {
    this.expandItems = true;
  }
}
