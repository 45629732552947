import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonValueAccessorBase } from '../utils/CommonValueAccessorBase';

@Component({
    selector: 'phx-switch-button',
    templateUrl: './phoenix-switch-button.component.html',
    styleUrls: ['./phoenix-switch-button.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoenixSwitchButtonComponent),
            multi: true
        }
    ]
})
export class PhoenixSwitchButtonComponent extends CommonValueAccessorBase<any> {
    // @Input('color') color: string = "green"; //blue, orange, green, red, ghost-red, ghost-orange
    @Input('text') text: string = null;
    // @Input('size') size: string = 'big'; //big, medium, small
    @Input('disabled') disabled: boolean = false;
    // @Input('loading') loading: boolean = false;

    constructor() {
        super();
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    afterSetValue() {
    }
}
