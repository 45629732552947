import { Injectable } from '@angular/core';
import { UserPermissions, UserPermissionsOperation, UserPermissionsSecurable } from 'phoenix-common';
import { ClientType } from '../../../phoenix-common/src/lib/shared/utils/Enumerations';
import { AuthService } from '@capp/app/auth/auth-service';

@Injectable({
  providedIn: 'root'
})

export class UserPermissionsService {
  private permissions: UserPermissions;

  // exported properties for template binding
  private clientInfo: any;

  constructor(private authService: AuthService) {
    const permissions = this.authService.getCurrentUserPermissions();
    this.setPermissions(permissions);
  }

  public setClientInfo(clientInfo: any) {
    this.clientInfo = clientInfo;
  }

  public setPermissions(permissions: any) {
    this.permissions = new UserPermissions().fromJSON(permissions);
  }

  public hasPermission(securable: UserPermissionsSecurable, operation: UserPermissionsOperation): boolean {
    const userHasNoPermissions = this.permissions?.securables?.size === 0;
    if (userHasNoPermissions) {
      // When a refresh of the browser happens we have a case where permissions might not have been set
      // for the Permission Service. In this case we try to get them and set them again.
      const permissions = this.authService.getCurrentUserPermissions();
      this.setPermissions(permissions);
    }
    return this.permissions?.hasPermission(securable, operation);
  }

  // ADMIN CRUD
  public isAdmin(): boolean {
    if(!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.ALL, UserPermissionsOperation.ALL);
  }

  //JOBS CRUD
  public canCreateJobs(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    if (this.clientInfo.clientType == ClientType.VENDOR_PAY) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.CREATE);
  }

  public canCreateLeases(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    if (this.clientInfo.clientType == ClientType.VENDOR_PAY) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.LEASES, UserPermissionsOperation.CREATE);
  }

  public canCreatePostings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.CREATE);
  }

  public canReadJobs(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.READ);
  }

  public canUpdateJobs(): boolean {
    if (!this.clientInfo) {
      return false;
    }

    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.UPDATE) &&
      (this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM);
  }

  public canUpdateLeases(): boolean {
    if (!this.clientInfo) {
      return false;
    }

    return this.hasPermission(UserPermissionsSecurable.LEASES, UserPermissionsOperation.UPDATE) &&
      (this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM);
  }

  public canUpdateJobStatus(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteJobs(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.JOBS, UserPermissionsOperation.DELETE);
  }

  //END JOBS CRUD

  //VENDORS CRUD
  public canCreateVendors(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.VENDORS, UserPermissionsOperation.CREATE);
  }

  public canReadVendors(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.VENDORS, UserPermissionsOperation.READ);
  }

  public canUpdateVendors(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.VENDORS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteVendors(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.VENDORS, UserPermissionsOperation.DELETE);
  }

  //END VENDORS CRUD


  //LOCATIONS CRUD
  public canCreateLocations(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.LOCATIONS, UserPermissionsOperation.CREATE)
      && (this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM);
  }

  public canReadLocations(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.LOCATIONS, UserPermissionsOperation.READ);
  }

  public canUpdateLocations(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return (this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM)
      && this.hasPermission(UserPermissionsSecurable.LOCATIONS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteLocations(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return (this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM)
      && this.hasPermission(UserPermissionsSecurable.LOCATIONS, UserPermissionsOperation.DELETE);
  }

  //END LOCATIONS CRUD

  //TENANTS CRUD
  public canCreateTenants(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.CREATE);
  }

  public canReadTenants(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.READ);
  }

  public canUpdateTenants(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteTenants(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.DELETE);
  }

  //TENANTS CRUD END

  //MESSAGES CRUD
  public canCreateMessages(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.MESSAGES, UserPermissionsOperation.CREATE);
  }

  public canReadMessages(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.MESSAGES, UserPermissionsOperation.READ);
  }

  public canUpdateMessages(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.MESSAGES, UserPermissionsOperation.UPDATE);
  }

  public canDeleteMessages(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.MESSAGES, UserPermissionsOperation.DELETE);
  }

  //END MESSAGES CRUD

  //CLIENT SETTINGS READ

  public canDoAllClientSettings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.CLIENT_SETTINGS, UserPermissionsOperation.ALL);
  }

  public canCreateClientSettings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.CLIENT_SETTINGS, UserPermissionsOperation.CREATE);
  }

  public canReadClientSettings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.CLIENT_SETTINGS, UserPermissionsOperation.READ);
  }

  public canUpdateClientSettings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.CLIENT_SETTINGS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteClientSettings(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.CLIENT_SETTINGS, UserPermissionsOperation.DELETE);
  }

  //END CLIENT SETTINGS

  //USERS CRUD
  public canCreateUsers(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.USERS, UserPermissionsOperation.CREATE);
  }

  public canReadUsers(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.USERS, UserPermissionsOperation.READ);
  }

  public canUpdateUsers(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.USERS, UserPermissionsOperation.UPDATE);
  }

  public canDeleteUsers(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.USERS, UserPermissionsOperation.DELETE);
  }

  //END USERS CRUD

  //muisc other ops / virtual permissions that combine multiple things


  public canCreateAccounts(isVendor) {
    return isVendor ? this.canCreateVendors() : this.canCreateTenants();
  }

  public canCreateVendorsOrTenants(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return this.hasPermission(UserPermissionsSecurable.VENDORS, UserPermissionsOperation.CREATE)
      || this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.CREATE);
  }

  public canCreateReqCategory(): boolean {
    if (!this.clientInfo) {
      return false;
    }
    return ((this.clientInfo.clientType == ClientType.SELF_SERVICE || this.clientInfo.clientType == ClientType.FREEMIUM) && this.canUpdateClientSettings());
  }

  public canUpdateCertusProperties(): boolean {
    return this.canUpdateClientSettings();
  }

  public canCreateContacts() {
    return this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.CREATE)
      || this.hasPermission(UserPermissionsSecurable.TENANTS, UserPermissionsOperation.CREATE);
  }
}
