import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { Utils } from '../shared/utils/utils';

@Component({
  selector: 'phx-input',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './phoenix-input.component.html',
  styleUrl: './phoenix-input.component.scss'
})
export class PhoenixInputComponent implements OnInit {
  @Input() inputType: string = 'text'; // text, date, currency, number, textarea
  @Input() inputValue: any;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() class: string = '';
  @Input() size: string = '';
  @Input() textAreaRowSize: string = '';
  @Input() textAreaColSize: string = '';
  @Input() hasError: boolean = false;
  @Input() title: string = '';
  @Input() centerText: boolean = false;
  @Input() rightAlignText: boolean = false;
  @Input() id: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    if (this.inputType == 'currency' && parseInt(this.inputValue) >= 0 && this.disabled) {
      this.inputValue = this.formatNumberToCurrencyString(parseInt(this.inputValue));
    }
  }

  onInputChange() {
    if (this.inputType != 'currency') {
      this.onChange.emit(this.inputValue);
    } else {
      this.onCurrencyInputChange();
    }
  }

  onFocusChange() {
    this.onFocus.emit();
  }

  //#region CURRENCY
  onCurrencyInputChange() {
    const values = Utils.formatMoney(this.inputValue);
    this.inputValue = values.displayValue;
    this.onChange.emit(values.displayValue)
  }

  formatNumberToCurrencyString(amount: number): string {
    const USDollar: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });

    return USDollar.format(amount);
  }

  onCurrencyKeyDown(evt: KeyboardEvent) {
    // Define allowed characters
    const allowedChars = '0123456789';

    // Prevent default if character is not allowed
    if (allowedChars.indexOf(evt.key) === -1 && evt.key !== 'Backspace' && evt.key !== 'ArrowLeft' && evt.key !== 'ArrowRight') {
      evt.preventDefault();
    }
  }

  //#endregion

  showRealDate(title: string) {
    try {
      let date = new Date(title + 'T00:00:00Z');

      let formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
      });

      return formatter.format(date);
    } catch (err) {
      return '';
    }
  }
}
