<div #phxMultiSelect class="phx-multi-select-container" (keyup)="onKeyPress($event)">
  <div class="dropdown-container" [ngClass]="size + ' ' + (error ? 'error' : '')">
    <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [id]="dropdownId + '-selected-item'"
         class="selected-item-container">
      <div [ngClass]="itemClass" class="selected-item" (click)="removedItem($event)"
           *ngIf="selectedItem && !isDisabled">
        <span class="item-name">{{ selectedItem[textField]}}</span>
      </div>
      <input #inputElement *ngIf="!selectedItem || isDisabled"
             [disabled]="isDisabled"
             [placeholder]="placeholder"
             (focus)="onFocusGained()"
             (blur)="onFocusLost()"
             [ngClass]="(isDisabled ? 'disabled ' : '') + itemClass"
             class="focusableItems dropdown-input phx-input"
             type="text"
             [(ngModel)]="input"
             [value]="input"
             (ngModelChange)="onSearch()"
      />
    </div>
    <div class="focusableItems arrow-container" *ngIf="options != null && !disabled" (click)="toggleDropDown(true)">
      <phx-icon src="filled-dropdown-arrow" mr="10" ml="5" color="black" size="10"></phx-icon>
    </div>
  </div>
  <div *ngIf="showDropDown && filteredOptions" [id]="dropdownId" class="focusableItems dropdown-content">
    <div *ngFor="let item of filteredOptions; let index = index"
         [id]="dropdownId + '-option-' + index"
         [ngClass]="dropdownId + '-option'"
         class="focusableItems option"
         (click)="onSelectedItem($event, item, true)"
         (keyup)="selectItemWithKeyPress($event, item)"
         [tabindex]="index + 1">{{item[textField]}}</div>
    <div *ngIf="filteredOptions?.length <= 0"
         class="no-option">{{ emptyDropdownMessage }}</div>
  </div>
</div>
