import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'phx-checkbox',
  templateUrl: './phoenix-checkbox.component.html',
  styleUrls: ['./phoenix-checkbox.component.scss']
})
export class PhoenixCheckboxComponent {
  @Input('checkedText') checkedText: string = '';
  @Input('uncheckedText') uncheckedText: string = '';
  @Input('text') text: string = '';
  @Input('checked') checked: boolean = false;
  @Input('disabled') disabled: boolean = false;
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  clicked(event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.onClick.emit(event);
    }
  }

  changed(event): void {
    event.stopPropagation();
    if (!this.disabled) {
      this.onChange.emit(event);
    }
  }
}
