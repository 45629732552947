import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { AppService } from '@capp/app/services/app.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@capp/app/auth/auth-service';

const jwtHelper = new JwtHelperService();

export interface ErrorResponse {
  status: number,
  message: string
}

export interface ResponsePaginated {
  range?: string,
  totalCount?: number,
  items?: Array<any>
}

export class BaseWebService {
  public static errorSubject = new Subject<ErrorResponse>();
  public certusApiPublicKey: string = environment.certusApiPublicKey;
  public certusApiUri: string = environment.certusApiUri;
  public webServiceUri: string = environment.webServiceUri;
  public middleWareUri: string = environment.middlewareUri;
  public timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  protected basePathWebService = '';

  constructor(protected http: HttpClient,
              protected appService: AppService,
              protected authService: AuthService,
              basePath?: string) {
    this.webServiceUri = environment.webServiceUri;

    if (this.webServiceUri.endsWith('/')) {
      this.webServiceUri = this.webServiceUri.substring(0, this.webServiceUri.length - 1);
    }

    if (basePath) {
      if (!basePath.startsWith('/')) {
        basePath = '/' + basePath;
      }
      this.basePathWebService = this.webServiceUri + basePath;
      if (!this.basePathWebService.endsWith('/')) {
        this.basePathWebService += '/';
      }
    }
  }

  protected extractData(res: Response) {
    return res || {};
  }

  protected handleError(error: Response | any) {
    let errorObj = error;
    const err = errorObj[Object.keys(errorObj)[0]];
    BaseWebService.errorSubject.next({ status: error.status, message: err });

    if (errorObj) {
      return throwError(errorObj);
    } else if (error && error.text) {
      return throwError(error.text().trim());
    } else {
      return throwError(error);
    }
  }

  protected async apiGetCall(url: string, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('TimeZone', this.timeZone);
    headers = headers.set('Content-Type', 'application/json');

    const token = this.authService.getIdToken();
    if (token && token != 'null') {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    return this.http
      .get(url, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError.bind(this)))
      .toPromise();
  }

  protected extractDataPaginated(response: HttpResponse<any>): ResponsePaginated {
    const rawRange = response.headers.get('content-range').split('/');
    const range = rawRange[0];
    const totalCount = rawRange[1];

    return {
      range,
      totalCount: parseInt(totalCount),
      items: response.body
    };
  }

  protected async apiGetDownload(url: string, headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
      headers = headers.set('Accept', 'application/pdf');
    }

    return await this.http.get(url, {
      headers: headers,
      responseType: 'blob',
      observe: 'response'
    }).pipe(map(this.downloadFile), catchError(this.handleError.bind(this))).toPromise();
  }

  protected async downloadFile(response) {
    const blob = new Blob([response.body], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    URL.revokeObjectURL(url);

    return true;
  }

  protected async apiGetCallPaginated(url: string, headers?: HttpHeaders) {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('TimeZone', this.timeZone);
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    const response = await this.http.get(url, {
      headers: headers,
      observe: 'response'
    }).pipe(catchError(this.handleError.bind(this))).toPromise();
    return this.extractDataPaginated(response);
  }

  protected async apiPostCallPaginated(url: string, headers: HttpHeaders, params: any) {
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('TimeZone', this.timeZone);
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    const response = await this.http.post(url, params, {
      headers: headers,
      observe: 'response'
    }).pipe(catchError(this.handleError.bind(this))).toPromise();
    return this.extractDataPaginated(response);
  }

  protected async apiPutCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('TimeZone', this.timeZone);
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    if (!params) {
      params = {};
    }
    return this.http
      .put(url, params, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  protected async apiPatchCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    if (!params) {
      params = {};
    }
    return this.http
      .patch(url, params, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  protected async apiDeleteCall(url: string, headers?: HttpHeaders, body: any = {}): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    return this.http
      .delete(url, { headers: headers, body: body })
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  protected async apiPostCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('TimeZone', this.timeZone);
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    if (!params) {
      params = {};
    }
    return this.http
      .post(url, params, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  protected async apiPostFormCall(url: string, params?: any, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
      headers = headers.set('TimeZone', this.timeZone);
    }

    if (!params) {
      params = {};
    }
    return this.http
      .post(url, params, { headers: headers })
      .pipe(map(this.extractData), catchError(this.handleError))
      .toPromise();
  }

  protected async apiUploadFileToS3(url: string, file: File, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
      headers = headers.set('Content-Type', file.type);
    }
    return await this.http.put(url, file, { headers }).toPromise();
  }

  protected async downloadFileAsBlob(url: string, params: any, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
    }
    const token = this.authService.getIdToken();
    if (token) {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
      headers = headers.set('TimeZone', this.timeZone);
    }

    const response = await this.http.post(url, params, { headers, responseType: 'blob' }).toPromise();
    return response as Blob;
  }
}
