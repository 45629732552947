import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'phx-single-select',
  templateUrl: './phoenix-single-select.component.html',
  styleUrls: ['./phoenix-single-select.component.scss']
})
export class PhoenixSingleSelectComponent implements OnInit, OnChanges {
  @Input('options') options: any[] = null;
  @Input('valueField') valueField = 'value';
  @Input('textField') textField = 'label';
  @Input('disabled') disabled: boolean = false;
  @Input('selectedItem') selectedItem: any;
  @Input('size') size: string = '';
  @Input('placeholder') placeholder: string = '';
  @Input('itemClass') itemClass: string = '';
  @Input('emptyDropdownMessage') emptyDropdownMessage: string = '';
  @Input('error') error = false;
  @Output('onChange') onChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('onError') onError: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('phxMultiSelect', { static: false }) phxMultiSelect: ElementRef;
  @ViewChild('inputElement') inputElement: ElementRef;
  public input = '';
  public filteredOptions = [];
  public showDropDown = false;
  public ShowDropDownTimeout: any;
  public isHovering = false;
  public dropdownId = this.generateRandomAlphanumeric();

  get isDisabled() {
    return (this.disabled || this.options == null);
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.filteredOptions = this.options?.slice(0);
    this.setInputField();
  }

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  setInputField() {
    if (!this.selectedItem) {
      this.input = '';
    } else {
      this.input = this.selectedItem[this.textField];
    }
    this.onSearch();
  }

  public toggleDropDown(didClickButton = false, forceToValue = null) {
    if (didClickButton) {
      if (forceToValue == null) {
        this.showDropDown = !this.showDropDown;
      } else {
        this.showDropDown = forceToValue;
      }
      if (this.ShowDropDownTimeout) {
        clearTimeout(this.ShowDropDownTimeout);
      }
      if (this.showDropDown && !this.selectedItem) {
        this.setFocus();
      }
      return;
    }

    this.ShowDropDownTimeout = setTimeout(() => {
      if (forceToValue == null) {
        this.showDropDown = !this.showDropDown;
      } else {
        this.showDropDown = forceToValue;
      }
    }, 100);
  }

  public onSearch() {
    if (!this.selectedItem) {
      this.filteredOptions = this.filterOptions();
    }
  }

  public onSelectedItem(evt: any, item: any, didClick = false) {
    if (item == null && this.selectedItem != null) {
      this.setInputField();
    }

    this.selectedItem = item;

    if (item != null) {
      this.setInputField();
    }
    this.onChange.emit(this.selectedItem);
    this.onSearch();

    this.error = false;
    this.toggleDropDown(didClick, false);
  }

  public removedItem(evt) {
    evt.stopPropagation();
    this.input = '';
    this.onSearch();

    this.selectedItem = null;
    this.onChange.emit(this.selectedItem);
    this.setFocus();
  }

  public filterOptions() {
    if (this.options == null) {
      return null;
    }
    return this.options.filter((f) =>
      f?.[this.textField]?.toLocaleLowerCase().includes(this.input.toLocaleLowerCase())
    );
  }
  
  public setFocus() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }

  public onFocusGained() {
    this.toggleDropDown(false, true);
  }

  public onFocusLost() {
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement && focusedElement.classList.contains('focusableItems')) {
        //focus just shifted to the drop down list... dont toggle
      } else {
        this.toggleDropDown(false, false);
      }
    }, 100);
  }

  public onKeyPress(evt: any) {
    if (evt.code === 'ArrowUp' || evt.code === 'ArrowDown') {
      if (this.ShowDropDownTimeout) {
        clearTimeout(this.ShowDropDownTimeout);
      }
      let counter: number = evt.code === 'ArrowUp' ? -1 : 1;
      const lastTabIndex = this.filteredOptions.length;
      const tabbables: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(`${this.dropdownId}-option`) as HTMLCollectionOf<HTMLElement>;
      let currentElement: HTMLElement = null;
      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i] === document.activeElement) {
          currentElement = tabbables[i];
        }
      }

      let curIndex = currentElement?.tabIndex ?? 0;
      if (curIndex === lastTabIndex && counter > 0) {
        curIndex = 0;
      } else if (curIndex === 1 && counter < 0) {
        curIndex = lastTabIndex + 1;
      }

      for (let i = 0; i <= tabbables.length - 1; i++) {
        if (tabbables[i].tabIndex === (curIndex + counter)) {
          tabbables[i].focus(); //if it's the one we want, focus it and exit the loop
          break;
        }
      }
    }
  }

  public selectItemWithKeyPress(evt: any, item: any) {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      this.onSelectedItem(evt, item, true);
    }
  }

  public generateRandomAlphanumeric(length: number = 6): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
}
