import { Options } from 'ngx-slider-v2';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComplianceLevelID, DeficiencyStatusID } from '../utils/constants';
import { CustomPropertyTypesEnum } from '../shared/utils/Constants';

export enum FilterType {
  vendors = 'vendors',
  tenants = 'tenants'
}

@Component({
  selector: 'phx-filter',
  templateUrl: './phoenix-filter.component.html',
  styleUrls: ['./phoenix-filter.component.scss']
})

export class PhoenixFilterComponent {
  @Output('onFilter') onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output('clearFilterCount') clearFilterCount: EventEmitter<any> = new EventEmitter<any>();
  @Input('filterCount') filterCount: number = 0;
  @Input('showCompliance') showCompliance = false;
  @Input('showTypes') showTypes = false;
  @Input('filterType') filterType: FilterType = FilterType.vendors;
  @Input('disableCompliance') disableCompliance = false;
  @Input('showFilterButton') showFilterButton = true;
  @Input('isNetworkFilter') isNetworkFilter = false;
  @Input('data') data: any;
  @Input('jobLabel') certificateLabel = 'Job';
  @Input('vendorLabel') companyLabel = 'Vendor';
  @Input('disabled') disabled = false;
  @Input('hideSearch') hideSearch = false;
  @Input('isAnalytics') isAnalytics = false;
  public isVendor = true;
  public searchInputValue = '';
  public trades: Array<any> = [];
  public selectedTrades: Array<number> = [];
  public modalRef: BsModalRef;
  public searchTimeout: any;
  public complianceLevelId: ComplianceLevelID;
  public selectedCompliance = [];
  public selectedVendorCompliance = [];
  public reqCategories = [];
  public subaccount_types = [];
  public jobTrades = [];
  public locations = [];
  public selectedLocation = null;
  public locationPlaceholder = '';
  public accountProperties = [];
  public subaccountProperties = [];
  public value = 3000;
  public jobComplianceLevels = [
    {
      id: DeficiencyStatusID.Major,
      label: 'Non Compliant'
    },
    {
      id: DeficiencyStatusID.Minor,
      label: 'Partially Compliant'
    },
    {
      id: DeficiencyStatusID.Compliant,
      label: 'Fully Compliant'
    }
  ];
  public vendorComplianceLevels = [
    {
      id: ComplianceLevelID.NonCompliant,
      label: 'Non Compliant'
    },
    {
      id: ComplianceLevelID.PartiallyCompliant,
      label: 'Partially Compliant'
    },
    {
      id: ComplianceLevelID.FullyCompliant,
      label: 'Fully Compliant'
    }
  ];
  public options: Options = {
    floor: 0,
    ceil: 3000
  };
  private subAccountPropertiesSelectedForFilter: { propertySelected: any; propertySelectedFrom: any }[] = [];
  private accountPropertiesSelectedForFilter: { propertySelected: any; propertySelectedFrom: any }[] = [];

  constructor(private modalService: BsModalService) {
  }

  private _searchParams: any = {};

  get searchParams() {
    return this._searchParams;
  }

  @Input('searchParams') set searchParams(value: any) {
    this._searchParams = value;
    this.setFilters();
  }

  public setFilters() {
    if (this._searchParams) {
      this.accountProperties = this.data?.accountProperties?.filter((x: any) => x.customFieldTypeId == 5 || x.customFieldTypeId == 6);
      this.subaccountProperties = this.data?.subaccountProperties?.filter((x: any) => x.customFieldTypeId == 5 || x.customFieldTypeId == 6);
      const selectedAccountProperties = this._searchParams?.custom_account_properties?.map((p: string) => +p) || [];
      const selectedSubAccountProperties = this._searchParams?.custom_subaccount_properties?.map((p: string) => +p) || [];
      const locations = this._searchParams?.locations?.map((p: string) => +p) || [];
      const reqCategories = this._searchParams?.req_categories?.map((p: string) => +p) || [];
      const selectedSubAccountPropertiesWithAllOtherProperties = [...selectedSubAccountProperties, ...locations, ...reqCategories];
      this.accountProperties?.forEach(property => {
        property.options = property.options.map((o: any) => {
          const isSelectedProperty = selectedAccountProperties.includes(o.id);
          if (isSelectedProperty) {
            const option = { ...o, selected: isSelectedProperty };
            this.propertySelected([option], false, property);
            return option;
          }
          return o;
        });
      });
      this.subaccountProperties?.forEach(p => {
        p.options = p.options.map((opt: any) => {
          const isLocationProperty = p.variable == 'locations';
          const isSelectedProperty = isLocationProperty
            ? selectedSubAccountPropertiesWithAllOtherProperties.includes(opt.phoenixId)
            : selectedSubAccountPropertiesWithAllOtherProperties.includes(opt.id);
          if (isSelectedProperty) {
            const option = { ...opt, selected: isSelectedProperty };
            this.propertySelected([option], true, p);
            return option;
          }
          return opt;
        });
      });

      this.selectedTrades = this._searchParams?.trades_param?.map((x: any) => Number(x)) || null;
      this.selectedCompliance = this._searchParams?.subaccount_compliance?.map((x: any) => this.jobComplianceLevels.find(c => +c.id == +x));
      this.selectedVendorCompliance = this._searchParams?.account_compliance?.map((x: any) => this.vendorComplianceLevels.find(c => +c.id == +x));

      this.value = this._searchParams.search_radius;
      this.searchInputValue = this._searchParams?.search_string || '';
    }
  }

  public showModal(modal: TemplateRef<any>) {
    if (this.data) {
      this.subaccount_types = this.data['subaccount_types'];
      this.accountProperties = this.data['accountProperties']?.filter((x: any) => x.customFieldTypeId == 5 || x.customFieldTypeId == 6);
      this.subaccountProperties = this.data['subaccountProperties']?.filter((x: any) => x.customFieldTypeId == 5 || x.customFieldTypeId == 6);
      this.locations = this.data['locations'];
      this.reqCategories = this.data['reqCategories'];
      this.trades = this.data['trades'];
    }
    this.setFilters();
    this.modalRef = this.modalService.show(modal);
  }

  public onLocationSelect(event: any) {
    this.selectedLocation = event;
    this.locationPlaceholder = [event?.address, event?.locality, event?.state, event?.country].filter(el => el).join(', ');
  }

  public onSearch() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.searchTimeout = setTimeout(() => {
      if (this.searchInputValue && this.searchInputValue.length < 0) {
        return;
      }

      const locationsSelectedFromProperties = [...new Set(this.getLocationsSelectedFromProperties())];
      const requirementCategoriesSelectedFromProperties = [...new Set(this.getRequirementCategoriesSelectedFromProperties())];
      const customAccountProperties = [...new Set(this.getCustomAccountProperties())];
      const customSubAccountProperties = [...new Set(this.getCustomSubAccountProperties())];
      const accountSelectedCompliance = [...new Set(this.selectedVendorCompliance?.map(x => +x.id))];
      const subAccountSelectedCompliance = [...new Set(this.selectedCompliance?.map(x => +x.id))];

      this.onFilter.emit({
        search_string: this.searchInputValue,
        locations: locationsSelectedFromProperties,
        trades: this.selectedTrades,
        subaccount_types: [],
        req_categories: requirementCategoriesSelectedFromProperties,
        subaccount_compliance: subAccountSelectedCompliance,
        account_compliance: accountSelectedCompliance,
        custom_account_properties: customAccountProperties,
        custom_subaccount_properties: customSubAccountProperties,
        lat: this.isNetworkFilter && this.selectedLocation ? this.selectedLocation?.lat : null,
        long: this.isNetworkFilter && this.selectedLocation ? this.selectedLocation?.long : null,
        radius: this.isNetworkFilter ? this.value : null
      });
    }, 800);
  }

  public onClearFilter() {
    this.searchInputValue = '';
    this.selectedTrades = [];
    this.accountPropertiesSelectedForFilter = [];
    this.subAccountPropertiesSelectedForFilter = [];
    this.selectedTrades = [];
    this.selectedCompliance = [];
    this.selectedVendorCompliance = [];
    this.selectedLocation = null;
    this.value = 3000;
    this.locationPlaceholder = '';
    this.clearFilterCount.emit({
      search_string: this.searchInputValue,
      trades: this.selectedTrades,
      subaccount_types: [],
      subaccount_compliance: this.selectedCompliance,
      account_compliance: this.selectedVendorCompliance,
      custom_account_properties: this.accountPropertiesSelectedForFilter,
      custom_subaccount_properties: this.subAccountPropertiesSelectedForFilter,
      lat: this.isNetworkFilter && this.selectedLocation ? this.selectedLocation?.lat : null,
      long: this.isNetworkFilter && this.selectedLocation ? this.selectedLocation?.long : null,
      radius: this.isNetworkFilter ? this.value : null
    });
  }

  propertySelected($event: any, isSubAccountProperty: boolean, property: any) {
    const idsSelected = $event?.map((e: any) => e.id);
    if (isSubAccountProperty) {
      this.removeExistingSubAccountProperties(property, idsSelected);
      this.subaccountProperties.forEach(propertyToAdd => {
        this.setSelectedAndAddToSubAccountProperties(idsSelected, propertyToAdd);
      });
    } else {
      this.removeExistingAccountProperties(property, idsSelected);
      this.accountProperties.forEach(propertyToAdd => {
        this.setSelectedAndAddToAccountProperties(idsSelected, propertyToAdd);
      });
    }
  }

  getSelectedAccountProperties(property: any) {
    const selectedIds = this.accountPropertiesSelectedForFilter?.map(p => {
      return p.propertySelected.selected ? p.propertySelected.id : null;
    });
    return property.options.filter((p: any) => selectedIds.includes(p.id));
  }

  getSelectedSubAccountProperties(property: any) {
    const selectedIds = this.subAccountPropertiesSelectedForFilter?.map(p => {
      return p.propertySelected.selected ? p.propertySelected.id : null;
    });
    return property.options.filter((p: any) => selectedIds.includes(p.id));
  }

  getName(property: any) {
    return property?.variable || property?.customFieldId;
  }

  private removeExistingAccountProperties(property: any, idsSelected: number[]) {
    const currentSelectedAccountProperties = this.accountPropertiesSelectedForFilter
      .filter((p: any) =>
        (p.propertySelectedFrom?.customFieldId != null && p?.propertySelectedFrom?.customFieldId == property?.customFieldId) ||
        p?.propertySelectedFrom?.variable == property?.variable);
    const propertiesToRemoveFromSelectedAccountProperties = currentSelectedAccountProperties
      .filter(p => !idsSelected.includes(p?.propertySelected?.id) && p?.propertySelectedFrom?.customFieldId == property?.customFieldId);
    const propertyOptionIdsToRemove = propertiesToRemoveFromSelectedAccountProperties
      .map(p => +p?.propertySelected?.id);
    this.accountPropertiesSelectedForFilter = this.accountPropertiesSelectedForFilter
      .filter(p => !propertyOptionIdsToRemove.includes(p?.propertySelected?.id));
  }

  private removeExistingSubAccountProperties(property: any, idsSelected: number[]) {
    const currentSelectedSubAccountProperties = this.subAccountPropertiesSelectedForFilter
      .filter((p: any) =>
        (p.propertySelectedFrom?.customFieldId != null && p?.propertySelectedFrom?.customFieldId == property?.customFieldId) ||
        p?.propertySelectedFrom?.variable == property?.variable);
    const propertiesToRemoveFromSelectedSubAccountProperties = currentSelectedSubAccountProperties
      .filter(p => !idsSelected.includes(p?.propertySelected?.id) && p?.propertySelectedFrom?.customFieldId == property?.customFieldId);
    const propertyOptionIdsToRemove = propertiesToRemoveFromSelectedSubAccountProperties
      .map(p => +p?.propertySelected?.id);
    this.subAccountPropertiesSelectedForFilter = this.subAccountPropertiesSelectedForFilter
      .filter(p => !propertyOptionIdsToRemove.includes(p?.propertySelected?.id));
  }

  private setSelectedAndAddToAccountProperties(idsSelected: number[], property: any) {
    idsSelected.forEach((idSelected: number) => {
      const propertySelected = property.options.find((option: any) => idSelected == option.id);
      if (propertySelected) {
        propertySelected.selected = true;
        this.accountPropertiesSelectedForFilter.push({ propertySelected, propertySelectedFrom: property });
      }
    });
  }

  private setSelectedAndAddToSubAccountProperties(idsSelected: number[], property: any) {
    idsSelected.forEach((idSelected: number) => {
      const propertySelected = property.options.find((option: any) => idSelected == option.id);
      if (propertySelected) {
        propertySelected.selected = true;
        this.subAccountPropertiesSelectedForFilter.push({ propertySelected, propertySelectedFrom: property });
      }
    });
  }

  private getLocationsSelectedFromProperties(): number[] {
    const result = this.subAccountPropertiesSelectedForFilter?.map(p => {
      if (p?.propertySelectedFrom?.variable == 'locations') {
        return p?.propertySelected?.phoenixId;
      }
    });

    return result.filter(p => p != null || p != undefined);
  }

  private getRequirementCategoriesSelectedFromProperties(): number[] {
    const result = this.subAccountPropertiesSelectedForFilter?.map(p => {
      if (p?.propertySelectedFrom?.variable == 'reqCategory') {
        return p?.propertySelected?.id;
      }
    });

    return result.filter(p => p != null || p != undefined);
  }

  private getCustomAccountProperties(): number[] {
    const result = this.accountPropertiesSelectedForFilter?.map(p => {
      const hasCustomFieldId = p?.propertySelectedFrom?.customFieldId > 0 || false;
      const isDropDownType = p?.propertySelectedFrom?.customFieldTypeId == CustomPropertyTypesEnum.DROPDOWN_SINGLE_SELECT ||
        p?.propertySelectedFrom?.customFieldTypeId == CustomPropertyTypesEnum.DROPDOWN_MULTI_SELECT;
      const isCustomDropDownProperty = hasCustomFieldId && isDropDownType;
      if (isCustomDropDownProperty) {
        return p?.propertySelected?.id;
      }
    });

    return result.filter(p => p != null || p != undefined);
  }

  private getCustomSubAccountProperties(): number[] {
    const result = this.subAccountPropertiesSelectedForFilter?.map(p => {
      const hasCustomFieldId = p?.propertySelectedFrom?.customFieldId > 0 || false;
      const isDropDownType = p?.propertySelectedFrom?.customFieldTypeId == CustomPropertyTypesEnum.DROPDOWN_SINGLE_SELECT ||
        p?.propertySelectedFrom?.customFieldTypeId == CustomPropertyTypesEnum.DROPDOWN_MULTI_SELECT;
      const isCustomDropDownProperty = hasCustomFieldId && isDropDownType;
      if (isCustomDropDownProperty) {
        return p?.propertySelected?.id;
      }
    });

    return result.filter(p => p != null || p != undefined);
  }

  canShowProperty(property: any) {
    const propsToShowOnAnalyticsFilter = ['locations', 'reqCategory'];
    if(this.isAnalytics) {
      return propsToShowOnAnalyticsFilter.includes(property?.variable);
    }
      return true;
  }
}
