import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'phx-dropdown-filter',
    templateUrl: './phoenix-dropdown-filter.component.html',
    styleUrls: ['./phoenix-dropdown-filter.component.scss'],
})
export class PhoenixDropdownFilterComponent implements OnInit {
    @Input('templateName') templateName: TemplateRef<any>;
    @Input('dropdownId') dropdownId: string;
    @Input('label') label: string;
    @Input('selectedCount') selectedCount: number;
    @Input('customClasses') customClasses: string;

    ngOnInit(): void {
      window.onclick = () => {
        this.closePopups();
      };
    }

    public openPopup(evt: any): void {
        evt.stopPropagation();
        const element = document.getElementById(this.dropdownId);
        if (element.classList.contains('active')) {
            this.closePopups();
            element.classList.remove('active');
        } else {
            this.closePopups();
            element.classList.add('active');
        }
    }

    public closePopups() {
        const popups = document.getElementsByClassName('popup');
        for (let i = 0; i <= popups.length - 1; i++) {
            popups[i].classList.remove('active');
        }
    }

    public onPopupBlur() {
      this.closePopups();
    }
}
