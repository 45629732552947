<div>
  <input
    (focus)="onFocusChange()"
    (input)="onInputChange()"
    *ngIf="inputType == 'text'"
    [(ngModel)]="inputValue"
    [disabled]="disabled"
    [ngClass]="class + ' ' + size + ' ' + (hasError ? 'error' : '') + (centerText ? 'centered-text' : '') + (rightAlignText ? 'right-align-text' : '')"
    [placeholder]="placeholder"
    [title]="title"
    [value]="inputValue"
    class="phx-input"
    type="text"
  />
  <textarea
    (focus)="onFocusChange()"
    (input)="onInputChange()"
    *ngIf="inputType == 'textarea'"
    [(ngModel)]="inputValue"
    [cols]="textAreaColSize"
    [disabled]="disabled"
    [ngClass]="class + ' ' + size + ' ' + (hasError ? 'error' : '')"
    [placeholder]="placeholder"
    [rows]="textAreaRowSize"
    [title]="title"
    [value]="inputValue"
  ></textarea>
  <input
    (focus)="onFocusChange()"
    (input)="onInputChange()"
    *ngIf="inputType == 'date'"
    [(ngModel)]="inputValue"
    [disabled]="disabled"
    [ngClass]="class + ' ' + size + ' ' + (hasError ? 'error' : '') + (centerText ? 'centered-date' : '')"
    [placeholder]="placeholder"
    [title]="showRealDate(title)"
    [value]="inputValue"
    class="phx-input date"
    type="date"
  />
  <input
    (focus)="onFocusChange()"
    (input)="onInputChange()"
    *ngIf="inputType == 'number'"
    [(ngModel)]="inputValue"
    [disabled]="disabled"
    [ngClass]="class + ' ' + size + ' ' + (hasError ? 'error' : '')"
    [placeholder]="placeholder"
    [title]="title"
    [value]="inputValue"
    class="phx-input"
    type="number"
  />
  <input
    (focus)="onFocusChange()"
    (input)="onInputChange()"
    (keydown)="onCurrencyKeyDown($event)"
    *ngIf="inputType == 'currency'"
    [(ngModel)]="inputValue"
    [disabled]="disabled"
    [id]="id"
    [ngClass]="class + ' ' + size + ' ' + (hasError ? 'error' : '') + (centerText ? 'centered-text' : '') + (rightAlignText ? 'right-align-text' : '')"
    [placeholder]="placeholder"
    [type]="'text'"
    [value]="inputValue"
    class="phx-input"
  />
</div>
