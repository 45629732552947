<input [(ngModel)]="asyncSelected"
       [formControl]="getFormControl()"
       (focusout)="focusout()"
       (keydown)="keydown($event)"
       [typeaheadAsync]="true"
       [typeahead]="dataSource"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       [typeaheadOptionsLimit]="5"
       typeaheadMinLength="3"
       typeaheadOptionField="address"
       [placeholder]="value && value.address ? value.address : placeholder"
       [readonly]="readonly"
       [ngClass]="(useBCSStyling ? 'phx-input' : 'form-control') + ' ' + (hasError ? 'error' : '')"
       data-testid="location-typeahead-input"
       class="{{classes}}"
>

