import { Component } from '@angular/core';
import { ClientTokenInfo } from '../../../../../phoenix-common/src/lib/shared/models/client-token-info';
import { AuthService } from '@capp/app/auth/auth-service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent {
  public decodedToken: ClientTokenInfo;

  constructor(
    private authService: AuthService
  ) {
    this.decodedToken = this.authService.getDecodedToken();
    this.decodedToken.token = this.authService.getIdToken();
  }
}
