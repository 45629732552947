<div class="container-fluid">
  <div class="row">
    <table data-testid="phoenix-datatable" class="table">
      <thead [ngClass]="theadClass">
      <th *ngFor="let column of columns"
          [style.width]="(column.type == columnType.DeficiencyStatus || column.type == columnType.Compliance) ? '50px' : 'auto'"
          [ngClass]="column.class">
        <div [ngSwitch]="true" class="d-flex h-100">
          <div class="checkbox-container" *ngSwitchCase="column.type === columnType.CheckBox">
            <div *ngIf="showTitleForCheckbox"> {{column.title}} </div>
            <div *ngIf="!showTitleForCheckbox">
              <phx-checkbox [disabled]="disabled" [checked]="checkAllChecked(column.variable)"
                            (onChange)="onSelectAll($event, column.variable)"></phx-checkbox>
            </div>
          </div>
          <div class="th-container default" [ngClass]="{'m-auto': hasCenterTypes(column)}" *ngSwitchDefault>
            {{column.title}}
            <div class="sort-arrows" *ngIf="column.sortable" (click)="changeSorting(column)">
              <phx-icon id="more-btn" src="filled-dropdown-arrow" mr="10" ml="5" color="black"
                        size="10"></phx-icon>
            </div>
          </div>
        </div>
      </th>
      <th *ngIf="accordionTemplate && !accordionToggleLeftSide"></th>
      </thead>
      <tbody [ngClass]="tbodyClass">
      <ng-container *ngFor="let row of rows; let i = index;">
        <tr [attr.data-testid]="'phoenix-datatable-row-' + i" class="phx-row" #rowElement [ngClass]="{
						'collapsed': row['collapse'] != null,
						'rowClass': row['collapse'] == null,
						'row-active': row['rowSelected'] != null
					  }"
            (click)="rowClick(row, rowElement)" style="z-index: 10000;" [id]="'row-'+i">
          <td class="column" *ngFor="let column of columns" style="max-width: 150px; overflow: inherit;"
              [style.width]="column.columnWidth">
            <div class="d-flex" [ngSwitch]="column.type">
              <!-- Start of new task types -->
              <div class="status m-auto " *ngSwitchCase="columnType.Status">
                <input [checked]="row[column.variable]" (click)="changeStatus($event, row)"
                       class="checkbox-round" type="checkbox" />
              </div>
              <div class="default" *ngSwitchCase="columnType.TaskName">
                <div [ngClass]="row['isCompleted'] ? 'strikethrough' : ''"
                     tooltip="{{row[column.variable]}}">{{row[column.variable]}}</div>
              </div>
              <div class="default" *ngSwitchCase="columnType.DocumentLink">
                <div class="bcs-link"
                     title="Click to navigate to Documents"
                     (click)="openDocumentLink($event, row)">
                  Document
                </div>
              </div>
              <div class="default" *ngSwitchCase="columnType.ActivityLink">
                <div class="bcs-link"
                     title="Click to navigate to Activities"
                     (click)="openActivityLink($event, row)">
                  Activity
                </div>
              </div>
              <div data-testid="company-name" class="default" *ngSwitchCase="columnType.VendorLink">
                <a class="account-link" (click)="openVendorLink($event, row)"
                   tooltip="{{row[column.variable]}}">{{row[column.variable]}}</a>
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.DueDate">
                <div [ngSwitch]="row[column.variable]">
                  <div *ngSwitchCase="'Due Today'"
                       class="due-date-status today">{{row[column.variable]}}</div>
                  <div *ngSwitchCase="'Overdue'"
                       class="due-date-status overdue">{{row[column.variable]}}</div>
                  <div *ngSwitchCase="'Upcoming'"
                       class="due-date-status upcoming">{{row[column.variable]}}</div>
                  <div *ngSwitchCase="'Completed'"
                       class="due-date-status completed">{{row[column.variable]}}</div>
                  <div *ngSwitchDefault class="due-date-status">{{row[column.variable] | date}}</div>
                </div>
              </div>
              <!-- End of new task types -->
              <div class="m-auto d-flex align-items-center justify-content-end"
                   *ngSwitchCase="columnType.ViewButton">
                <phx-button data-id="commonPhoenixDatatableViewBtn" color="blue" text="View"
                            (click)="viewClick(row)"></phx-button>
              </div>
              <div class="checkbox-container" *ngSwitchCase="columnType.CheckBox">
                <phx-checkbox [disabled]="row.alwaysDisabled ? row.alwaysDisabled : disabled"
                              [checked]="row.alwaysChecked ? row.alwaysChecked : row[column.variable]"
                              (onChange)="onCheckBoxChanged($event, row, column.variable)"></phx-checkbox>
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.Icon">
                <div>✔</div>
                <!--TODO ICON TYPE-->
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.Money">
                {{row[column.variable] | currency:'USD':'symbol':'1.0-0'}}
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.Rating">
                <rating [(ngModel)]="row[column.variable]" max="5" readonly="true"
                        style="color: #FDAF29;font-size: 25px;"></rating>
              </div>
              <div *ngSwitchCase="columnType.Rows">
                <div *ngFor="let r of row[column.variable]">
                  {{r || "-"}}
                </div>
              </div>
              <div *ngSwitchCase="columnType.RowsMoney">
                <div *ngFor="let r of row[column.variable]">{{(r | currency:'USD':'symbol':'1.0-0')
                || "-"}}</div>
              </div>
              <div data-testid="compliance-status" class="m-auto" *ngSwitchCase="columnType.Compliance">
                <div class="lds-ring" *ngIf="loadingCompliance; else complianceColumnTemplate">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <ng-template #complianceColumnTemplate>
                  <div *ngIf="row?.isActive; else inactiveStatus;" [ngSwitch]="row[column.variable]">
                    <phx-icon customClass="standardImage" src="compliant"
                              *ngSwitchCase="complianceLevelID.FullyCompliant"
                              tooltip="Fully Compliant"></phx-icon>
                    <phx-icon customClass="standardImage" src="compliant-amber"
                              *ngSwitchCase="complianceLevelID.PartiallyCompliant"
                              tooltip="Partially Compliant"></phx-icon>
                    <phx-icon customClass="standardImage" src="compliant-amber"
                              *ngSwitchCase="complianceLevelID.MinorCompliant"
                              tooltip="Minor Compliant"></phx-icon>
                    <phx-icon customClass="standardImage" src="non-compliant" *ngSwitchDefault
                              tooltip="Non-Compliant"></phx-icon>
                  </div>
                  <ng-template #inactiveStatus>Inactive</ng-template>
                </ng-template>
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.DeficiencyStatus">
                <div *ngIf="row?.isPending; else notPendingStatus;"  >Pending</div>
                <ng-template  #notPendingStatus >
                  <div *ngIf="row?.isActive; else inactiveStatus;"  [ngSwitch]="row[column.variable]">
                    <phx-icon customClass="standardImage" src="compliant"
                              *ngSwitchCase="deficiencyStatusID.Compliant"
                              tooltip="Compliant"></phx-icon>
                    <phx-icon customClass="standardImage" src="compliant-amber"
                              *ngSwitchCase="deficiencyStatusID.Minor"
                              tooltip="Minor Compliant"></phx-icon>
                    <phx-icon customClass="standardImage" src="non-compliant"
                              *ngSwitchCase="deficiencyStatusID.Major"
                              tooltip="Non-Compliant"></phx-icon>
                    <div *ngSwitchCase="deficiencyStatusID.FeeDue">Fee Due</div>
                  </div>
                </ng-template>
                <ng-template #inactiveStatus>Inactive</ng-template>
              </div>
              <div class="m-auto" *ngSwitchCase="columnType.CustomButton">
                <ng-container
                  *ngTemplateOutlet="column.buttonCustomTemplate; context: { $implicit: row }">
                </ng-container>
              </div>
              <div class="ellipsis" tooltip="{{row[column.variable] | jobTypePipe}}"
                   containerClass="service-tooltip" *ngSwitchCase="columnType.JobTypes">
                {{row[column.variable] | jobTypePipe}}
              </div>
              <div class="ellipsis" tooltip="{{row[column.variable] | leaseTypePipe}}"
                   containerClass="service-tooltip" *ngSwitchCase="columnType.LeaseTypes">
                {{row[column.variable] | leaseTypePipe}}
              </div>
              <div class="ellipsis" tooltip="{{row[column.variable] | jobTradePipe}}"
                   containerClass="service-tooltip" *ngSwitchCase="columnType.JobTrades">
                {{row[column.variable] | jobTradePipe}}
              </div>
              <div [attr.data-testid]="createDataTestId(column.variable) + '-property-' + i"
                   class="ellipsis"
                   tooltip="{{row[column.variable]}}"
                   containerClass="service-tooltip" *ngSwitchCase="columnType.Ellipsis">
                {{row[column.variable]}}
              </div>
              <div class="ellipsis" tooltip="{{row[column.variable]}}"
                   containerClass="service-tooltip" *ngSwitchCase="columnType.Date">
                {{row[column.variable] | date}}
              </div>
              <div [attr.data-testid]="createDataTestId(column.variable) + '-property-' + i"
                   class="default flex-grow-1" *ngSwitchDefault>
                {{row[column.variable]}}
              </div>
            </div>
          </td>
          <td *ngIf="accordionTemplate && !accordionToggleLeftSide">
            <div class="arrow-down" [class.accordion-is-opened]="row.accordionExpanded"></div>
          </td>
        </tr>
        <tr class="accordion-container" *ngIf="accordionTemplate">
          <td [attr.colspan]="columns.length + 1" style="padding: 0; cursor: auto;">
            <div [ngClass]="row.accordionExpanded ? 'expanded' : ''"
                 [collapse]="!(accordionTemplate && row.accordionExpanded)" [isAnimated]="true">
              <ng-template *ngTemplateOutlet="accordionTemplate; context: { $implicit: row }">
              </ng-template>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
